import axios from 'axios'
import API from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/aplicaciones`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  actualizar(aplicacion) {
    let formData = new FormData(),
      headers = { headers: { 'Content-Type': 'multipart/form-data' } }

    Object.keys(aplicacion).forEach(key => {
      if(aplicacion[key] == null) return
      formData.append(key, aplicacion[key])
    })

    // Asignación de los roles al formData
    if(aplicacion.roles != undefined) {
      formData.delete('roles')
      aplicacion.roles.forEach(idRol => {
        formData.append("roles[]", idRol)
      })
    }

    return apiClient.post(`${aplicacion.id}`, formData, headers)
  },

  guardar(aplicacion) {
    let formData = new FormData(),
      headers = { headers: { 'Content-Type': 'multipart/form-data' } }

    Object.keys(aplicacion).forEach(key => {
      if(aplicacion[key] == null) return
      formData.append(key, aplicacion[key])
    })

    // Asignación de los roles al formData
    if(aplicacion.roles != undefined) {
      formData.delete('roles')
      aplicacion.roles.forEach(idRol => {
        formData.append("roles[]", idRol)
      })
    }

    return apiClient.post('', formData, headers)
  },

  eliminar(idAplicacion) {
    return apiClient.delete(`${idAplicacion}`)
  },

  aplicacionJSON(idAplicacion) {
    return apiClient.get(`${idAplicacion}.json`)
  },

  aplicacionesJSON(params) {
    return apiClient.get(`${API}/aplicaciones.json`, { params: params })
  }
}
