<template>
  <div class="mb-4">
    <ul class="nav nav-pills nav-justified">
      <li class="nav-item waves-effect waves-light">
        <router-link
          class="nav-link"
          :class="{ 
            active: vista == 'usuarios'
          }"
          :to="{ name: 'listadoUsuarios' }"
        >
          Usuarios
        </router-link>
      </li>
      <li class="nav-item waves-effect waves-light">
        <router-link
          :to="{ name: 'listadoRolesUsuarios' }"
          class="nav-link"
          :class="{ 
            active: vista == 'roles'
          }"
        >
          Roles
        </router-link>
      </li>
      <li class="nav-item waves-effect waves-light">
        <router-link
          :to="{ name: 'listadoAplicaciones' }"
          class="nav-link"
          :class="{ 
            active: vista == 'aplicaciones'
          }"
        >
          Aplicaciones
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Adm3ArgusblackCmpSecciones',
  props: ['vista']
}
</script>

<style scoped>
.no-cursor{
  cursor: default;
}
</style>